main {
  display: flex;
  flex-wrap: nowrap;
  height: -webkit-fill-available;
  height: 100vh;
  max-height: 100vh;
  overflow-x: auto;
  /* overflow-y: hidden; */
}

.tab-content > .active {
  display: flex !important;
  flex: 1 1 auto !important;
  flex-direction: column !important;
}

.ag-icon-custom-stats {
  background: transparent
    url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgc3Ryb2tlPSIjN0Y4QzhEIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xMC41IDZWNC41aC01di41MzJhMSAxIDAgMCAwIC4zNi43NjhsMS43MTggMS40MzJhMSAxIDAgMCAxIDAgMS41MzZMNS44NiAxMC4yYTEgMSAwIDAgMC0uMzYuNzY4di41MzJoNVYxMCIvPjxyZWN0IHg9IjEuNSIgeT0iMS41IiB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIHJ4PSIyIi8+PC9nPjwvc3ZnPg==)
    no-repeat center;
  background-size: 16px 16px;
  height: 16px;
  opacity: 0.87;
  width: 16px;
  max-width: 16px;
  display: inline-block;
}

.modal-90w {
  max-width: none!important;
  width: 90%!important;
}

.ag-cell .btn {
  font-size: var(--ag-font-size);
}

.ag-row-voided div {
  text-decoration: line-through !important;
}